import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import Vue from 'vue';
import { BrochureState } from '~/types/types';
var isClient = function isClient(me) {
  return me && me.roles.includes('client');
};
var isTagManager = function isTagManager(me) {
  return me && me.roles.includes('tag_manager');
};
var isTagger = function isTagger(me) {
  return me && me.roles.includes('tagger');
};
var isTaggerOnly = function isTaggerOnly(me) {
  return me && me.roles.length === 1 && me.roles[0] === 'tagger';
};
var isStatisticsManager = function isStatisticsManager(me) {
  return me && (me.roles.includes('statistics_manager') || me.roles.includes('superadmin'));
};
var isAdmin = function isAdmin(me) {
  return me && me.roles.includes('admin');
};
var isSuperAdmin = function isSuperAdmin(me) {
  return me && me.roles.includes('superadmin');
};
var isClientManager = function isClientManager(me) {
  return me && me.roles.includes('client_manager');
};
var isAdManager = function isAdManager(me) {
  return me && me.roles.includes('ad_manager');
};
var isCityManager = function isCityManager(me) {
  return me && me.roles.includes('city_manager');
};
var brochureStatesAllowed = function brochureStatesAllowed(me) {
  if (me && (me.roles.includes('superadmin') || me.roles.includes('admin'))) {
    return [BrochureState.Inbox, BrochureState.ForApproval, BrochureState.Tagging, BrochureState.TaggingVerification, BrochureState.NotPublished, BrochureState.Active, BrochureState.Archived, BrochureState.Trash];
  } else if (me && me.roles.includes('tag_manager')) {
    return [BrochureState.Tagging, BrochureState.TaggingVerification];
  } else if (me && me.roles.includes('tagger')) {
    return [BrochureState.Tagging];
  } else if (me && me.roles.includes('client')) {
    return [BrochureState.Inbox, BrochureState.ForApproval, BrochureState.Active, BrochureState.Trash];
  }
};
var authorization = function authorization(context, inject) {
  inject('isAdmin', isAdmin);
  inject('isClient', isClient);
  inject('isTagManager', isTagManager);
  inject('isTagger', isTagger);
  inject('isTaggerOnly', isTaggerOnly);
  inject('isStatisticsManager', isStatisticsManager);
  inject('isSuperAdmin', isSuperAdmin);
  inject('isClientManager', isClientManager);
  inject('isAdManager', isAdManager);
  inject('isCityManager', isCityManager);
  inject('brochureStatesAllowed', brochureStatesAllowed);
};
export default authorization;